import dynatic from '../index';
import { expand } from 'specdom_tools';

let http_request = new XMLHttpRequest();
if (!http_request) {
  alert('Cannot create an XMLHTTP instance');
} else {
  http_request.onreadystatechange = load_pages;
  http_request.open('GET', 'pages.json');
  http_request.send();
}
function load_pages() {
  if (http_request.readyState === XMLHttpRequest.DONE) {
    if (http_request.status === 200) {
      let pages = JSON.parse(http_request.responseText);
      for ( let page_id in pages ){
        pages[page_id] = expand( pages[page_id] );
      }
      console.log('Loading Dynatic...');
      //dynatic(pages, settings);
      dynatic(pages);
    } else {
      alert('There was a problem with the request.');
    }
  }
}

