var process_text = require('./process_text');


var re_table_sep = /^\|\s*--|^\|\s*:--/;

function process_table(table_array) {
  var table_header = true;

  var rows = [];

  table_array.forEach(function(line){
    var table_sep_match = line.trim().match(re_table_sep);

    var cells = line.split('|');
    cells.slice(1);
    if(cells[cells.length-1] === ''){
      cells = cells.slice(1,-1);
    } else {
      cells = cells.slice(1);
    }


    if(table_sep_match){
      table_header = false;
    } else {
      rows.push({
        tag: 'tr',
        children: cells.map(function(cell){
          return {
            tag: table_header ? 'th' : 'td',
            children: process_text(cell),
          };
        }),
      });
    }

  });

  return rows;
}

module.exports = process_table;
