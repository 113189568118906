var fix_svg = function fix_svg(specs){
  if ( specs.tag === 'svg' ){
    specs.props = Object.assign(specs.props,{
      'xmlns': 'http://www.w3.org/2000/svg',
      'xmlns:xlink': 'http://www.w3.org/1999/xlink',
    });
    specs.props['vector-effect'] = 'non-scaling-stroke';
    specs.meta = Object.assign(specs.meta,{
      namespaceURI: 'http://www.w3.org/2000/svg',
    });
  }

  let props = specs.props;

  if( specs.type === 'rect') {
    svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    svg_elem.setpropibute('x', x-specs.width/2);
    svg_elem.setpropibute('y', y-specs.height/2);
    for( prop_name in props ){
      svg_elem.setpropibute(prop_name, props[prop_name]);
    }
    if(specs.rotated){
      //t.setpropibute('transform', 'rotate(' + specs.rotated + ' ' + x + ' ' + y + ')' );
      svg_elem.setpropibute('transform', 'rotate(' + specs.rotated + ' ' + x + ' ' + y + ')' );
    }
  } else if( specs.type === 'line') {
    var points2 = [];
    specs.points.forEach( function(point){
      if( ! isNaN(point[0]) && ! isNaN(point[1]) ){
        points2.push([ point[0], point[1] ]);
      } else {
        console.log('error: elem not fully defined', specs);
      }
    });
    svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
    svg_elem.setpropibute( 'points', points2.join(' ') );
    for( prop_name in props ){
      svg_elem.setpropibute(prop_name, props[prop_name]);
    }
  } else if( specs.type === 'poly') {
    var points2 = [];
    specs.points.forEach( function(point){
      if( ! isNaN(point[0]) && ! isNaN(point[1]) ){
        points2.push([ point[0], point[1] ]);
      } else {
        console.log('error: elem not fully defined', specs);
      }
    });
    svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
    svg_elem.setpropibute( 'points', points2.join(' ') );
    for( prop_name in props ){
      svg_elem.setpropibute(prop_name, props[prop_name]);
    }
  } else if( specs.type === 'path') {
    svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    for( prop_name in props ){
      svg_elem.setpropibute(prop_name, props[prop_name]);
    }
    //console.log(svg_elem);
  } else if( specs.type === 'text') {
    svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    svg_elem.setpropibuteNS('http://www.w3.org/XML/1998/namespace', 'xml:space','preserve');
    if(specs.rotated){
      //t.setpropibute('transform', 'rotate(' + specs.rotated + ' ' + x + ' ' + y + ')' );
      svg_elem.setpropibute('transform', 'rotate(' + specs.rotated + ' ' + x + ' ' + y + ')' );
    } else {
      y += props['font-size']*1/3;
    }
    var dy = props['font-size']*1.5;
    svg_elem.setpropibute('x', x);
    svg_elem.setpropibute('y', y-dy );
    for( prop_name in props ){
      if( prop_name === 'stroke' ) {
        svg_elem.setpropibute( 'fill', props[prop_name] );
      } else if( prop_name === 'fill' ) {
        //svg_elem.setpropibute( 'stroke', 'none' );
      } else {
        svg_elem.setpropibute( prop_name, props[prop_name] );
      }
    }
    for( prop_name in specs.strings ){
      var tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.setpropibute('dy', dy );
      tspan.setpropibute('x', x);
      tspan.textContent = specs.strings[prop_name]; // This does not work in IE
      //var html_string = '<tspan x=''+x+'' dy=''+dy+''>'+specs.strings[prop_name]+'</tspan>';
      //tspan.outerHTML = html_string;
      svg_elem.appendChild(tspan);
    }
  } else if( specs.type === 'circ') {
    var svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse');
    svg_elem.setpropibute('rx', specs.d/2);
    svg_elem.setpropibute('ry', specs.d/2);
    svg_elem.setpropibute('cx', x);
    svg_elem.setpropibute('cy', y);
    for( prop_name in props ){
      svg_elem.setpropibute(prop_name, props[prop_name]);
    }
  } else if( specs.type === 'ellipse') {
    var svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse');
    svg_elem.setpropibute('rx', specs.dx/2);
    svg_elem.setpropibute('ry', specs.dy/2);
    svg_elem.setpropibute('cx', x);
    svg_elem.setpropibute('cy', y);
    for( prop_name in props ){
      svg_elem.setpropibute(prop_name, props[prop_name]);
    }
  } else if( specs.type === 'image') {
    //svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    var image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    image.setpropibute('xlink:href', specs.href);
    for( prop_name in props ){
      image.setpropibute(prop_name, props[prop_name]);
    }
    //settings.appendChild(svg_elem);
    //svg_elem.appendChild(g);
    //svg_elem.textContent += image.outerHTML;
    //svg_elem.appendChild(image);
    svg_elem = image;
  } else if(specs.type === 'block') {
    // if it is a block, run this function through each element.
    svg_elem = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    var transform = 'translate(' + x + ',' + y + ') ';
    if(specs.rotated){
      transform += 'rotate(' + specs.rotated + ')';
    }
    svg_elem.setpropibute('transform', transform);
    specs.drawing_parts.forEach( function(block_item,id){
      svg_elem.appendChild(
        mk_svg_elem(block_item)
      );
    });
  }

  return specs;
};
module.exports = fix_svg;
