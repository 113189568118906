var mkDOM = require('./mkDOM.js');


var configChanged = function(old_specs, new_specs){
  var old_specs_clone = JSON.parse(JSON.stringify(old_specs));
  old_specs_clone.children = undefined;
  var new_specs_clone = JSON.parse(JSON.stringify(new_specs));
  new_specs_clone.children = undefined;
  return JSON.stringify(old_specs_clone) !== JSON.stringify(new_specs_clone);
};

var default_level = 0;

function DOM_update(sdom,new_specs){
  // console.log('_______');
  var old_specs = ( sdom && sdom.specs ) || {};
  // sdom.specs = new_specs;

  if( ! new_specs ) {
    // console.log('|>');
    // console.log('|>');
    if( sdom && sdom.elem ){
      if(sdom.elem.parentNode){
        sdom.elem.parentNode.removeChild(sdom.elem);
      } else {
        console.log('parrentNode missing:',sdom,new_specs);
      }
    }
    sdom = undefined;
  } else if( configChanged(old_specs, new_specs) ){ // CHANGE
    console.log('A/B');
    var existing_elem = sdom.elem;
    sdom = sdom.mkDOM(new_specs,sdom);
    if(existing_elem.parentNode){
      existing_elem.parentNode.replaceChild(sdom.elem,existing_elem);
    } else {
      console.log('parrentNode missing:',existing_elem);
    }
    // parent.append(sdom);
  } else if( JSON.stringify(old_specs.children) !== JSON.stringify(new_specs.children) ){ // Children changed
    var oldLength = ( sdom && sdom.children && sdom.children.length ) || 0;
    var newLength = ( new_specs && new_specs.children && new_specs.children.length ) || 0;
    var l = Math.max(oldLength,newLength);
    // console.log(oldLength,newLength,l);
    // console.log( old_specs.children.map(function(child){return child;}) );
    // console.log( new_specs.children.map(function(child){return child;}) );
    var new_children = [];
    // sdom.clear()
    for(var i = 0; i < l; i++) {
      var old_child_sdom = sdom && sdom.children && sdom.children[i];
      var new_child_specs = new_specs && new_specs.children && new_specs.children[i];
      // console.log(i,new_child_specs);
      if( ! old_child_sdom ){
        // sdom.append(sdom.mkDOM(new_child_specs));
        new_children.push( sdom.mkDOM(new_child_specs) );
      } else {
        // sdom.children[i] = DOM_update(old_child_sdom, new_child_specs);
        new_children.push( DOM_update(old_child_sdom, new_child_specs) );
      }
    }
    sdom.clear().append(new_children);
    //*/
  } else {
    // console.log('_');
  }

  return sdom;
}

module.exports = DOM_update;
