export default function(state){

  var title = state.title;
  var menu_items = state.menu_items;
  var selected_page_id = state.selected_page_id;
  var page_content = state.page_content;

  var menu_specs = {
    tag: 'div',
    props: {
      id: 'menu'
    },
    children: []
  };

  menu_specs.children.push({
    tag: 'span',
    text: '[',
    props: {
      class: 'menu_bracket',
    }
  });

  menu_items.forEach(function(menu_item){
    //var name = f.pretty_name(menu_item.name);
    var name = menu_item.name.trim();
    var selected = menu_item.href.slice(2) === selected_page_id;
    if ( selected_page_id === 'default' && menu_item.href === '#/'){
      selected = true;
    }
    // console.log('menu_item.href',menu_item.href,selected_page_id,selected);
    //console.log('selected', page_id, selected_page_id, selected);
    menu_specs.children.push({
      tag: selected ? 'span' : 'a',
      text: name,
      props: {
        class: selected ? 'site_title titlebar_link titlebar_link_selected' : 'site_title titlebar_link',
        href: menu_item.href
      }
    });
  });

  menu_specs.children.push({
    tag: 'span',
    text: ']',
    props: {
      class: 'site_title menu_bracket',
    }
  });

  title = title.constructor === Array ? title : [title];
  var titlebar_content = {
    tag: 'div',
    props: {
      class: 'titlebar_content'
    },
    children: [
      {
        tag: 'div',
        id: 'site_title',
        class: 'site_title_section',
        _c: title.map( subtitle => {
          return {
            _:'a',
            _t:subtitle,
            _p:{href:'#/'}
          };
        }),
      },
      menu_specs,
    ]
  };

  var specs = {
    tag: 'div',
    children: [
      {
        tag: 'div',
        props: {
          class: 'titlebar'
        },
        children: [titlebar_content]
      },
      {
        tag: 'div',
        props: {
          class: 'transition'
        }
      },
      page_content
    ]
  };

  return specs;
}
