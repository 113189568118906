var wrapper_prototype = require('./wrapper_prototype');
const fix_tag = {
  svg: require('./fix_svg'),
};

var cspec_replace = {
  '_': 'tag',
  '_c': 'children',
  '_t': 'text',
  '_m': 'meta',
  '_p': 'props',
  '_s': 'style',
};

function decompact(spec){
  Object.keys(spec).forEach( (keyname)=>{
    if ( cspec_replace[keyname] ){
      spec[cspec_replace[keyname]] = spec[keyname];
      delete spec[keyname];
    }
  });
  //*
  let tag = spec.tag;
  delete spec.tag;
  let text = spec.text;
  delete spec.text;
  let children = spec.children;
  delete spec.children;
  let meta = spec.meta;
  delete spec.meta;
  let elem = spec.elem;
  delete spec.elem;
  let style = spec.style;
  delete spec.style;
  let props = Object.assign({}, spec.props);
  delete spec.props;
  props = Object.assign(props, spec);
  if ( Object.entries(props).length === 0 ) props = undefined;
  for (const prop of Object.getOwnPropertyNames(spec)) {
    delete spec[prop];
  }
  spec = Object.assign(spec, {
    tag,
    text,
    props,
    children,
    meta,
    elem,
    style,
  });
  if ( spec.props === undefined || Object.entries(spec.props).length === 0 ) delete spec.props;
  if ( spec.children === undefined ) delete spec.children;
  if ( spec.meta === undefined || Object.entries(spec.meta).length === 0 ) delete spec.meta;
  return spec;
}

/**
* mkDOM - Makes DOM Element from a ConfigDOM specs object
* @function
* @param  {object} specs ConfigDOM specs object
* @return {element} DOM Element
*/
var mkDOM = function mkDOM(specs, sdom){
  if ( !specs ){
    specs = {};
  }
  ///////////////////////////////
  // Prep specs
  if ( specs.constructor === Number ){
    specs = specs.toString();
  }
  if ( specs.constructor === String ){
    //sdom = document.createTextNode(specs);
    specs = {
      tag: 'textNode',
      text: specs
    };
  }
  if ( specs.nodeName !== undefined || specs.constructor.prototype === HTMLElement || specs instanceof SVGElement ) { // NODE ELEMENT
    specs = {
      tag: 'elem',
      elem: specs
    };
  }

  specs = decompact(specs);
  if ( specs.children && specs.children.constructor !== Array ) {
    specs.children = [specs.children];
  }
  specs.props = specs.props || {};
  specs.meta = specs.meta || {};
  ///////////////////////////
  if ( fix_tag[specs.tag] !== undefined ){
    specs = fix_tag[specs.tag](specs);
  }
  ///////////////////////////
  // make element
  sdom = sdom || Object.create(wrapper_prototype);
  sdom.mkDOM = mkDOM;
  sdom.specs = specs;
  sdom.children = [];
  var namespaceURI = specs.props.namespaceURI || false;
  namespaceURI = specs.namespaceURI || namespaceURI;
  namespaceURI = specs.meta.namespaceURI || namespaceURI;
  if ( specs.tag === 'elem' ) { // NODE ELEMENT
    sdom.elem = specs.elem;
    sdom.specs = {};
  } else { // CONFIG
    if ( namespaceURI ) {
      sdom.elem = document.createElementNS(namespaceURI, specs.tag);
    } else if ( specs.textNode ) {
      sdom.elem = document.createTextNode(specs.text);
    } else if ( specs.tag ){
      sdom.elem = document.createElement(specs.tag);
    } else {
      console.log('Elem type not found.', specs);
      return false;
    }
    if ( specs.props ){
      for ( let name in specs.props ){
        if ( name === 'value'){
          if ( specs.props[name] !== undefined ){
            sdom.value(specs.props[name]);
          }
        } else {
          sdom.attr(name, specs.props[name]);
        }
      }
    }
    if ( specs.style ){
      for ( let name in specs.style ){
        sdom.style(name, specs.style[name]);
      }
    }
    if ( specs.text ){
      sdom.text( specs.text );
    }
  }
  if ( specs.children && specs.children.length ){
    for (var i = 0; i < specs.children.length; i++) {
      var newChild = specs.children[i];
      if ( newChild.constructor === Number ){
        newChild = newChild.toString();
      }
      if ( newChild.constructor === String ){
        newChild = {
          tag: 'textNode',
          text: newChild
        };
      }
      if ( namespaceURI ){
        newChild.meta = newChild.meta || {};
        newChild.meta.namespaceURI = namespaceURI;
      }
      var child_sdom = mkDOM(newChild);
      if ( child_sdom && child_sdom.constructor === Object ){
        // Add shild sDOM to this sDOM's child array
        //sdom.children.push(child_sdom);
        // Append child element to this element, with built in function.
        sdom.append(child_sdom);
      }
    }
  }
  if ( specs.props && specs.props.value !== undefined){
    sdom.value(specs.props.value);
  }
  return sdom;
};


module.exports = mkDOM;
