module.exports = {
  sections: {
    '!': {
      followed_by: '[',
      closer: ')',
    },
    '[': {
      not_preceded_by: '!',
      closer: ')',
    },
    '_': {
      preceded_by: ' ',
      closer: '_',
    },
    /*
    '_': {
      preceded_by: ' ',
      followed_by: '_',
      closer: '__',
    },
    */
    '*': {
      preceded_by: ' ',
      closer: '*',
    },
    /*
    '*': {
      preceded_by: ' ',
      followed_by: '*',
      closer: '**',
    },
    */
    '{': {
      followed_by: '{',
      closer: '}}',
    },
  },
};
