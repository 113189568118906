var process_table = require('./process_table');
var process_text = require('./process_text');
var get_props = require('./get_props');
/**
 * .
 * @exports
 */
module.exports = function(markdown_text, settings){
  var page_specs = {
    tag: 'div',
    props: {
      class: 'level_0'
    },
    meta: {},
    children: [],
  };
  var lines = markdown_text.split('\n');
  if( ( lines[0].match( /^.*:/ ) || lines[0].slice(0,3) === '---' ) && lines[0][0] !== '#' ){
    var document_parts = markdown_text.split('\n\n');
    var meta_lines = document_parts[0].split('\n');
    lines = lines.slice( meta_lines.length+1 );
    meta_lines.forEach(function(line){
      if( line.trim() !== '' && line.trim() !== '---' ){
        page_specs.meta = page_specs.meta || {};
        var re_clean_params = /<!---|<!--|-->|--->|\||{%|%}/g;
        line = line.replace(re_clean_params,'');
        var line_parts = line.split(':');
        let key = line_parts[0].trim();
        let value = line_parts.slice(1).join(':').trim();
        page_specs.meta[key] = value;
      }
    });
  }
  var re_indent = /^[ ]+/;
  var re_table = /^\|/;
  //var re_special = /^\#+|^\*|^\-{3}/;
  var re_special = /^#+|^ *\*|^ *\+|^ *-|^ *[0-9]+\.|^-{3}/;
  var re_classy = /{([^}]*)}/;
  var paragraph_children = [];
  var pre_code_children = [];
  var table_lines = [];
  var h_level = 0;
  var h_parent = [
    page_specs.children
  ];
  var root_ul = false;
  var ul_lastIndent = 0;
  var ul_level = 0;
  var ul_parent = [];
  var root_ol = false;
  var ol_lastIndent = 0;
  var ol_level = 0;
  var ol_parent = [];
  var classy_match = false;
  var class_name;
  var class_name_custom = '';
  lines.forEach(function(line, i){
    var indent = 0;
    if( line.match(re_indent) ){
      indent = Math.floor(line.match(re_indent)[0].length/2);
    }
    var special_match = line.trim().match(re_special);
    var table_match = line.match(re_table);
    var line_trimed = line.trim();
    classy_match = line_trimed.match(re_classy);
    if( classy_match ){
      console.log('---');
      console.log('line',line);
      class_name_custom= classy_match[0].slice(1,-1);
      line_trimed = line_trimed.replace(classy_match[0],'');
      line = line.replace(classy_match[0],'');
      console.log('class_name_custom',class_name_custom);
    }
    if( line_trimed === '' ){ // blank line
      ul_level = 0;
      ul_lastIndent = 0;
      if( root_ul ){
        h_parent[h_level].push(root_ul);
        root_ul = false;
        ul_parent = [];
      }
      ol_level = 0;
      ol_lastIndent = 0;
      if( root_ol ){
        h_parent[h_level].push(root_ol);
        root_ol = false;
        ol_parent = [];
      }
    }
    if( line_trimed === '' || special_match ){ // blank line or speial character
      // Complete paragraph
      if( paragraph_children[0] !== undefined ){
        h_parent[h_level].push({
          tag: 'p',
          children: paragraph_children,
          props: { class: class_name_custom || undefined },
        });
        paragraph_children = [];
        class_name_custom = '';
      }
    }
    if( indent < 1 && pre_code_children[0] !== undefined ){
      h_parent[h_level].push({
        tag: 'code',
        children: [
          {
            tag: 'pre',
            text: pre_code_children.join('\n'),
          }
        ],
        props: { class: class_name_custom || undefined },
      });
      class_name_custom = '';
      pre_code_children = [];
    }
    if( ! table_match && table_lines[0] !== undefined  ){
      h_parent[h_level].push({
        tag: 'table',
        children: process_table(table_lines),
        props: { class: class_name_custom },
      });
      table_lines = [];
      class_name_custom = '';
    }
    if( table_match ){
      table_lines.push(line_trimed);
    } else if( special_match ) { // special
      var text = line_trimed.slice(special_match[0].length).trim();
      var desc_parts = get_props(text);
      var spec = {};
      if ( Object.keys(desc_parts.props).length !== 0 ){ spec.props = desc_parts.props; }
      if ( Object.keys(desc_parts.style).length !== 0 ){ spec.style = desc_parts.style; }
      spec.props = spec.props || {};
      spec.props = Object.assign( {}, spec.props, { class: class_name_custom || undefined } );
      //if( line_trimed[0] === '#' ){ // new heading
      if( line[0] === '#' ){ // new heading
        var new_hlevel;
        var h_count = line.match(/^#+/)[0].length;
        if ( h_count === 1 ) {
          new_hlevel = 1;
        } else {
          new_hlevel = h_count - 1;
        }
        class_name = class_name_custom !== '' ? class_name_custom + ' section level_'+new_hlevel : 'section level_'+new_hlevel;
        var section_spec = {
          tag: 'div',
          text: undefined,
          props: {
            class: class_name,
          },
          children: [],
        };
        var level_offset = new_hlevel - h_level;
        if( new_hlevel >= 2 && ! h_parent[h_level+level_offset-1] ) {
          class_name = class_name_custom !== '' ? class_name_custom + ' section level_'+new_hlevel-1 : 'section level_'+new_hlevel-1;
          var parent_spec = {
            tag: 'div',
            text: undefined,
            props: {
              class: class_name,
            },
            children: [],
          };
          h_parent[h_level+level_offset-1-1].push(parent_spec);
          h_parent[h_level+level_offset-1] = parent_spec.children;
        }
        h_parent[h_level+level_offset-1].push(section_spec);
        h_level = new_hlevel;
        h_parent[h_level] = section_spec.children;
        spec.tag = 'h'+h_count;
        spec.children = process_text(desc_parts.text);
        h_parent[h_level].push(spec);
      } else if( line_trimed.match(/^-{3}/) ){ // --- horizontal line
        spec.tag = 'hr';
        h_parent[h_level].push(spec);
      } else if ( (line_trimed[0] === '*' && line_trimed[1] !== '*' ) || line_trimed[0] === '+' || line_trimed[0] === '-' ){ // bullet list
        var indent_delta = indent - ul_lastIndent;
        ul_lastIndent = indent;
        if( ! root_ul ){
          root_ul = {
            tag: 'ul',
            props: {
              class: class_name_custom + ' list_level_0'
            },
            children: [],
          };
          ul_parent[0] = root_ul.children;
        }
        if( indent_delta > 0 ) {
          ul_level++;
          var ul = {
            tag: 'ul',
            props: {
              class: class_name_custom + ' list_level_'+ul_level
            },
            children: [],
          };
          //h_parent[h_level].push(ul);
          ul_parent[ul_level] = ul.children;
          ul_parent[ul_level-1].push( ul );
        } else if( indent_delta < 0 ) {
          ul_level--;
        }
        spec.tag = 'li';
        spec.props = spec.props || {};
        spec.props.class = spec.props.class || '';
        spec.props.class += ' list_level_'+ul_level;
        spec.children = [{
          tag: 'span',
          children: process_text(desc_parts.text),
        }];
        ul_parent[ul_level].push(spec);
      } else if( line_trimed.match(/^[0-9]+\./) ){ // numbered list
        var indent_delta = indent - ol_lastIndent;
        ol_lastIndent = indent;
        if( ! root_ol ){
          root_ol = {
            tag: 'ol',
            props: {
              class: class_name_custom + ' list_level_0'
            },
            children: [],
          };
          ol_parent[0] = root_ol.children;
        }
        if( indent_delta > 0 ) {
          ol_level++;
          var ol = {
            tag: 'ol',
            props: {
              class: class_name_custom + ' list_level_'+ol_level
            },
            children: [],
          };
          //h_parent[h_level].push(ol);
          ol_parent[ol_level] = ol.children;
          ol_parent[ol_level-1].push( ol );
        } else if( indent_delta < 0 ) {
          ol_level--;
        }
        spec.tag = 'li';
        spec.props = spec.props || {};
        spec.props.class = spec.props.class || '';
        spec.props.class += ' list_level_'+ol_level;
        spec.children = [{
          tag: 'span',
          children: process_text(desc_parts.text),
        }];
        ol_parent[ol_level].push(spec);
      }
    } else if( indent >= 1 ){
      pre_code_children.push(line.slice(2));
    } else if( line_trimed !== '' ) { // paragraph line
      if( line.slice(-1) !== ' ') line += ' ';
      paragraph_children = paragraph_children.concat(process_text(line));
    }
  });
  return page_specs;
};
