var DOM_update = require('./DOM_update.js');

/**
 * Prototype object for the element wrapper.
 */
var wrapper_prototype = {
  text: function(string){
    if(this.elem){
      this.elem.textContent = string;
    } else {
      console.log('elem missing');
    }
    return this;
  },
  append: function(input){
    if( input.constructor === Array ){
      for( var i=0; i<input.length; i++ ){
        this.append(input[i]);
      }
    } else {
      var child_sdom;
      if( input.elem ){
        child_sdom = input;
        this.elem.appendChild(child_sdom.elem);
        this.children.push(child_sdom);
      } else {
        child_sdom = this.mkDOM(input);
        this.elem.appendChild(child_sdom.elem);
        this.children.push(child_sdom);
      }

      /*
      if( input.constructor === String || input.constructor === Number){
        input = document.createTextNode(input);
        this.elem.appendChild(input);
      } else if( input.constructor === Node ){
        this.elem.appendChild(input);
      } else if( input.tag ){
        var sub_sdom = this.mkDOM(input);
        this.elem.appendChild(sub_sdom.elem);
      } else if( input.elem ){
        this.elem.appendChild(input.elem);
      }
      this.children.push(input);
      */
    }
    return this;
  },
  appendTo: function(parentElement){
    if( parentElement.constructor === Node ){
      parentElement.appendChild(this.elem);
    } else {
      parentElement.append(this);
    }
    return this;
  },
  attr: function(name, value, attributeNS){
    var attributeName;
    if( name === 'class'){
      attributeName = 'class';
    } else {
      attributeName = name;
    }
    if( typeof value === 'function' ){
      this.elem[attributeName] = value;
    } else if( value === undefined ){
      return this.elem.getAttribute(attributeName);
    } else if( value === null ){
      this.elem.removeAttribute(attributeName);
    } else if( attributeName === 'innerHTML' ){
      this.elem[attributeName] = value;
    } else if( attributeNS !== undefined ){
      this.elem.setAttributeNS(attributeNS,attributeName, value);
    } else {
      this.elem.setAttribute(attributeName, value);
    }
    //console.log(attributeName, value);
    return this;
  },
  unwrap: function(){
    return this.elem;
  },
  clear: function(){
    //this.elem.innerHTML = '';
    console.log( this.elem );
    while (this.elem.firstChild) {
      this.elem.removeChild(this.elem.firstChild);
    }
    this.children = [];
    return this;
  },
  load: function(new_specs){
    console.log(new_specs);
    DOM_update(this,new_specs);
    console.log(this);
  },
  parent: function(){
    return this.mkDOM(this.elem.parentNode);
  },
  set: function(new_element){
    var parent = this.parent(new_element);
    parent.clear().append(new_element);
    return this;
  },
  set_value: function(new_value){
    if( new_value !== undefined){
      this.value(new_value);
    }
    return this;
  },
  style: function(name, value){
    if( value === undefined ){
      return this.elem.style[name];
    }
    this.elem.style[name] = value;
    return this;
  },
  value: function(new_value){
    if( new_value === undefined){
      return this.elem.value;
    } else {
      if( this.elem && this.elem.value !== undefined ){
        this.elem.value = new_value;
        return this;
      } else {
        return false;
      }
    }
  },
  xml: function(){
    return this.elem.outerHTML;
  },
};


/**
 * @constructor Wraps an HTMLElement with a jquery like function.
 * @param  {HTMLElement} element
 * @return {function} HTMLElement wrapped by SimpleDOM
 */
// function Wrap(element){
//   var W = Object.create(wrapper_prototype);
//   W.elem = element;
//   return W;
// }


/**
 * @exports wrapper_prototype
 */
module.exports = wrapper_prototype;
