/**
* this is the app
* @file_overview this the starting point for the application.
* @author keith showalter {@link https://github.com/kshowalter}
* @version 0.1.0
*/
import $ from 'specdom';
import hash_router from 'hash_router';
import state_manager from 'state_manager';
import reducers from './src/reducers';
//import require_pages from './require_pages';
export default function dynatic(pages, settings){
  settings = settings || {};
  //var context = require.context( MD_DIR, true, /\.md$/);
  //var pages = require_pages(context);
  let menu_items = false;
  let menu_spec_c = false;
  let title = false;
  let title_spec_c = false;
  let default_page_name = 'default';
  if ( pages['home'] !== undefined ){
    default_page_name = 'home';
  }
  if ( pages['menu'] ){
    let menu_md_specs_c = pages['menu'].children[0].children;
    if ( menu_md_specs_c[0].tag === 'h1' ) {
      title_spec_c = menu_md_specs_c[0].children;
      if ( menu_md_specs_c[1] && menu_md_specs_c[1].tag === 'ul' ) {
        menu_spec_c = menu_md_specs_c[1].children;
      }
    }
    if ( menu_md_specs_c[0].tag === 'li' ) {
      menu_spec_c = menu_md_specs_c;
    }
    if ( title_spec_c ){
      title = title_spec_c;
    }
    if ( menu_spec_c ){
      menu_items = menu_spec_c.map(
        li_spec => {
          return {
            name: li_spec.children[0].children[0].children[0],
            href: li_spec.children[0].children[0].props.href,
          };
        }
      );
    }
  }
  pages['_page_index'] = {
    tag: 'div',
    class: 'level_0',
    id: 'page',
    meta: {
      location: [],
      name: '_page_index',
      title: 'Page Index',
    },
    children: {
      tag:'div',
      class:'section level_1',
      children: {
        tag:'div',
        class:'section level_2',
        children: {
          tag:'ul',
          children: Object.keys(pages).map(page_name=>{
            return {
              tag:'li',
              children: {
                tag:'a',
                text: pages[page_name].meta.title +' ('+ pages[page_name].meta.page_id +')',
                href: '#/'+page_name,
              },
            };
          })
        }
      }
    }
  };
  console.log('pages',pages);
  var init_state = {
    title: title || settings.title || 'website',
    menu_items: menu_items || settings.menu_items || [],
    pages: pages,
    default_page_name: settings.default_page_name || default_page_name,
    selected_page_id:  '',
    page_content: {},
    specs: {},
    body_style: {},
  };
  var sdom = $('body');
  var actions = state_manager(init_state, reducers, function(state){
    // console.log('SPECS', state.specs);
    document.title = state.title;
    sdom.clear().append(state.specs);
    window.scrollTo(0, 0);
    // apply top level sytle to document.body
    if ( state.page_content.style ){
      Object.keys(state.body_style).forEach(k=>state.body_style[k]=null);
      state.body_style = Object.assign(state.body_style, state.page_content.style);
      Object.keys(state.body_style).forEach(k=>{
        document.body.style[k] = state.body_style[k];
      });
    }
    if ( settings.onload ) {
      console.log('Starting .onload');
      settings.onload();
    }
  });
  var router = hash_router(function(selection){
    actions.load_page(selection);
  });
  router();
}
