//var special_chars = require('./special_chars');

module.exports = function get_props(string){
  //console.log(string)
  string = string || '';
  var desc_parts = {
    text: string,
    props: {},
    style: {}
  };
  var mark = string.length-1;
  while( mark > -1 ){
    if( [')'].indexOf(string[mark]) !== -1 ){
      desc_parts.text = string;
      mark = -1;
      break;
    }
    if( string[mark] === '|' ){
      break;
    }
    mark--;
  }
  if( mark >= 0 ){
    desc_parts.text = string.slice(0,mark);
    var props_string = string.slice(mark+1);
    if( props_string ){
      var props_parts = props_string.trim().split(',');
      props_parts.forEach(function(prop_string){
        var value_parts = prop_string.trim().split(/:|=/g);
        var name_parts = value_parts[0].trim().split('.');
        if( name_parts[0] === 'style'){
          desc_parts.style[name_parts.slice(1).join('.')] = value_parts[1];
        } else {
          desc_parts.props[value_parts[0]] = value_parts[1];
        }
      });
    }
  }
  return desc_parts;
};
