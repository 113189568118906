var get_props = require('./get_props');
var special_chars = require('./special_chars');
//ar re_find_links = /\[(.*?)\]\((.+?)\)/g;
//var re_split_line = /\[!\[.*?\]\(.+?\)\]\(.+?\)|(\[.*?\]\(.+?\)|!\[.*?\]\(.+?\))/g;
//var re_split_line = /!\[(.*?)\]\((.+?)\)|(\[.*?\]\(.+?\))/g;
//var re_split_links = /\[(.*?)\]\((.+?)\)/;
var re_divide_links = /\]\(/g;
//var re_split_links = /\(|\)|\[|\]/g;
//var re_split_images = /!\[(.*?)\]\((.+?)\)/;
//var starters = special_chars.starters;
//var closers = special_chars.closers;

var convert_special = function(to_convert_string){
  var specs = {};
  if ( to_convert_string[0] === '!' ){
    var img_parts = to_convert_string.trim().slice(2,-1).split(re_divide_links);
    var desc_parts = get_props(img_parts[0]);
    let text = desc_parts.text;
    delete( desc_parts.text );
    specs = Object.assign({},desc_parts);
    specs.tag = 'img';
    specs.props.alt = text;
    specs.props = specs.props || {};
    specs.props.src = img_parts[1];
  } else if ( to_convert_string[0] === '['){
    var link_parts = to_convert_string.trim().slice(1,-1).split(re_divide_links);
    var href = link_parts.pop();
    var content = link_parts.join('](');
    var desc_parts = get_props(content);
    content = desc_parts.text;
    delete( desc_parts.text );
    specs = Object.assign({},desc_parts);
    specs.tag = 'a';
    specs.props = specs.props || {};
    specs.props.href = href;
    specs.children = process_text(content);
  } else if ( to_convert_string[0] === '*' || to_convert_string[0] === '_' ){
    var tag;
    if ( to_convert_string[1] === '*' || to_convert_string[1] === '_' ){
      tag = 'strong';
    } else {
      tag = 'em';
    }
    var text = to_convert_string.replace(/\*/g, ' ').replace(/_/g, ' ').trim();
    specs = {
      tag: tag,
      // text: desc_parts.text,
      //text: text,
      children: [text],
    };
  } else if ( to_convert_string[0] === '{' ){
    var text = to_convert_string.slice(2,-2).trim().replace(/\s/g, '_');
    specs = {
      tag: 'span',
      _p: {
        id: text,
        class: 'variable',
      },
      _m: {
        type: 'variable',
        id: text,
      },
    };
  } else {
    specs = to_convert_string;
  }
  return specs;
};

var process_text = function process_text(line_string){
  // console.log('-------- ');
  var wait = false;
  var em = false;
  var start = 0;
  var end = 0;
  var starter = false;
  var closer = false;
  var level = 0;
  var output = [];
  if ( ! line_string ){
    return false;
  }
  for( var c = 0; c<line_string.length; c++){
    if ( special_chars.sections[line_string[c]] !== undefined ){
      var section = special_chars.sections[line_string[c]];
      if (
           ! ( section.preceded_by !== undefined && line_string[c-1] !== undefined && line_string[c-1] !== section.preceded_by )
        && ! ( section.not_preceded_by !== undefined && line_string[c-1] !== undefined && line_string[c-1] === section.not_preceded_by )
        && ! ( section.followed_by !== undefined && line_string[c+1] !== undefined && line_string[c+1] !== section.followed_by )
        && ! ( section.not_followed_by !== undefined && line_string[c+1] !== undefined && line_string[c+1] === section.not_followed_by )
      ){
        level++;
      } else {
        section = false;
      }
    }
    if ( section && ! closer ) { // no closer means we are in a special section
      if ( line_string[c] === '[' && line_string[c-1] === '!' ){ // if trigger is second character of image, then skip
        section = false;
      }
      starter = line_string[c];
      closer = section.closer;
      start = c;
    }
    if ( closer && starter === line_string[c] && ! em ){ // Begin special section of text
      //level++;
    }
    if ( closer === '*' || closer === '_' ) {
      if ( line_string[c] === '*' || line_string[c] === '_' ){
        wait = true;
      }
      if (em && ((line_string[c] === '_' && line_string[c + 1] === '_') || (line_string[c] === '*' && line_string[c + 1] === '*'))) {
        wait = false;
      }
      if ( (line_string[c] === '_' && line_string[c + 1] !== '_') || (line_string[c] === '*' && line_string[c + 1] !== '*')) {
        if (em){
          wait = false;
        }
        em = !em;
      }
    }
    if ( closer ){
      let section_closed = false;
      if ( ! wait && closer.slice(-1) === line_string[c] ){ // End special section of text
        section_closed = true;
      }
      if ( ! wait && closer.length > 1 && closer[1] !== line_string[c-1] ){ // End special section of text
        section_closed = false;
      }
      if ( section_closed ){
        level--;
        if (level === 0){
          if ( line_string.slice(0,start) !== '' ){
            output.push(line_string.slice(end,start));
          }
          output.push(convert_special(line_string.slice(start,c+1)));
          end = c + 1;
          closer = false;
          wait = false;
          // em = false;
        }
      }
    }
  }
  if ( output.length !== 0 ){ // Add closing text to output
    if ( line_string.slice(end) !== '' ){
      output.push(line_string.slice(end));
    }
  } else { // Nothing special was found, outputting input sting
    output.push(line_string);
  }
  return output;
};

module.exports = process_text;
