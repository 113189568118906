const variate = require('specdom_tools').variate;
import mk_page_specs from './mk_page_specs';
export default {
  load_page: function(state,action){
    var selection = action.arguments[0];
    console.log('selection: ', selection);
    if( ! selection ){
      console.log('re-ROUTING... to home');
      selection = [state.default_page_name];
      console.log('selection: ', selection);
    }
    selection = selection.filter( string => {
      return string !== '';
    });
    var selected_page_id = state.selected_page_id = selection.join('/');
    var selected_location = selection.slice(0,selection.length-1).join(' / ');
    if( ! selected_location ){
      selected_location = '/';
    }
    console.log('ROUTING... '+selected_page_id);
    var page = state.pages[selected_page_id];
    console.log(state.pages)
    console.log(page)
    var page_content;
    if( ! page && ! state.pages[404]){
      page_content = {
        tag: 'p',
        text: '404 page not found'
      };
    } else if( ! page ){
      page_content = state.pages[404];
    } else {
      page_content = page;
    }
    state.page_content = page_content;
    var specs = mk_page_specs(state);
    let page_vars = state.page_content.meta;
    specs = variate(specs,page_vars);
    console.log('SPECS', specs);
    state.specs = specs;
    return state;
  },
};
