/**
* A simple DOM manipulation tool.
 * @fileOverview Constructor for SimpleDOM function.
 * @author Keith Showalter {@link https://github.com/kshowalter}
 * @version 0.2.0
 */


/**
 * @module
 */
var mkDOM = require('./lib/mkDOM.js');
//var window;
//var document;
//var HTMLElement;
//var SVGElement;
try {
  document = window.document;
}
catch(error) {
  //console.warn('window/document not availible, using jsdom');
  console.warn('window/document not availible');
  console.warn('If using in Node, try using jsdom');
  /*
  const jsdom = require('jsdom');
  const { JSDOM } = jsdom;
  const dom = new JSDOM('<!DOCTYPE html>');
  global.window = dom.window;
  global.document = dom.window.document;
  global.HTMLElement = window.HTMLElement;
  global.SVGElement = window.SVGElement;
  */
}

/**
 * Wraps an HTMLElement with a jquery like function
 * @param  {string} input
 * @return {SimpleDOM} Wrapped HTMLElement
 */
var $ = function $(input, specs){
  if ( typeof input === 'undefined' ) {
    console.log('input needed, using "body"');
    // return false;
    input = 'body';
  }

  if ( input.constructor === Object && input.elem ){
    return input;
  }

  if ( input.constructor === Object && ( input.tag || input._ ) && specs === undefined ){
    specs = input;
    input = false;
  }

  if ( input ){
    var target_sdom;
    var element;
    var elements;
    if ( input.nodeName !== undefined || input.constructor.prototype === HTMLElement || input.constructor.prototype === SVGElement ) {
      element = input;
      target_sdom = mkDOM(element);
    } else if ( input.substr && input.substr(0,1) === '#' ) {
      element = document.getElementById(input.substr(1));
      if ( element ){
        target_sdom = mkDOM(element);
      } else {
        target_sdom = false;
        console.error('Element not found for id:',input);
      }
    } else if ( input.substr && input.substr(0,1) === '.' ) {
      elements = [].slice.call(document.getElementsByClassName(input.substr(1)));
      target_sdom = elements.map(function(element){ return (mkDOM(element)); });
    } else if ( input === 'body' ) {
      elements = document.body;
      target_sdom = mkDOM(elements);
    } else {
      specs = specs || {};
      specs.tag = input;
    }
  }

  if ( specs ){
    var sdom = mkDOM(specs);
    if (target_sdom){
      target_sdom.append(sdom);
    }
    return sdom;
  } else {
    return target_sdom;
  }

};

/**
 * Constructor for SimpleDOM function.
 * @exports $
 */
module.exports = $;
